import React from 'react';
import PropTypes from 'prop-types';
import { LoadingSpinner } from '../LoadingSpinner';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { useFetchAndLoad } from '../../common/hooks';
import { LabelForm } from '../LabelForm';
import { Input } from '../InputText';
import { SelectOptionActionButton } from '../SelectOptionActionButton';
import { SearchFieldAutocompletePagination } from '../SearchFieldAutocompletePagination';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import { ColFlex, RowFlex } from '../../styles';
import { theme } from '../../common';
import { SelectForm } from '../SelectForm';
import { FaTrashCan } from 'react-icons/fa6';
import { ButtonForm } from '../button';

const CustomFilterMixTable = ({
  columns,
  openModal,
  onCloseModal,
  onCancelModal,
  filas,
  onAddFiltro,
  onDeleteFiltro,
  onDeleteValor,
  onLimpiarFiltros,
  listaOperaciones,
  handleChangeCondicion,
  handleChangeColumna,
  handleChangeOperador,
  handleChangeInput,
  onSelectValue,
  onMultiSelectValue,
  changeMultiInput,
  handleChangeMultiInput,
  handleKeyDownMultiInput,
  handleCreateMultiInput,
  onAplicarFiltrosMultiples,
  fetchDataPaginate,
}) => {
  const dispatch = useDispatch();
  const { loading, callEndpoint } = useFetchAndLoad();

  const components = {
    DropdownIndicator: null,
  };
  const createOption = (label) => ({
    label,
    value: label,
  });
  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState([]);

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
    }
  };

  const condicionales = [
    {
      value: 1,
      label: 'And',
    },
    {
      value: 2,
      label: 'Or',
    },
  ];

  const customStyleSelect = (oParam) => {
    const customStyles =
      oParam === null || oParam === true
        ? {
            control: (provided, state) => ({
              ...provided,
              backgroundColor: 'rgb(255, 255, 255)',
              boxShadow: state.isFocused ? 'rgb(0 0 0 / 8%) 1px 2px 10px' : 0,
              borderColor: state.isFocused ? '#D0EAE2' : '#CED4DA',
              '&:hover': {
                borderColor: state.isFocused ? '#D0EAE2' : '#CED4DA',
                boxShadow: state.isFocused ? '0 0 5px rgb(3, 201, 215)' : 0,
              },
              borderRadius: '5px',
              fontFamily: theme.font.family,
              fontSize: '12px',
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              fontFamily: theme.font.family,
              fontSize: '12px',
            }),
            menu: (provided, state) => ({
              ...provided,
              fontFamily: theme.font.family,
              fontSize: '12px',
              zIndex: 999999,
            }),
            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected ? 'rgba(3, 201, 215, 0.08)' : 'white',
              color: 'black !important',
              '&:hover': {
                backgroundColor: state.isSelected
                  ? 'rgba(3, 201, 215, 0.08)'
                  : 'rgba(0, 0, 0, 0.03)',
              },
            }),
          }
        : {
            control: (provided, state) => ({
              ...provided,
              backgroundColor: 'rgb(255, 255, 255)',
              boxShadow: state.isFocused ? 'rgb(0 0 0 / 8%) 1px 2px 10px' : 0,
              borderColor: '#FF2F00',
              '&:hover': {
                borderColor: state.isFocused ? '#D0EAE2' : '#CED4DA',
                boxShadow: state.isFocused ? '0 0 5px rgb(3, 201, 215)' : 0,
              },
              borderRadius: '5px',
              fontFamily: theme.font.family,
              fontSize: '12px',
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              fontFamily: theme.font.family,
              fontSize: '12px',
            }),
            menu: (provided, state) => ({
              ...provided,
              fontFamily: theme.font.family,
              fontSize: '12px',
              zIndex: 999999,
            }),
            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected ? 'rgba(3, 201, 215, 0.08)' : 'white',
              color: 'black !important',
              '&:hover': {
                backgroundColor: state.isSelected
                  ? 'rgba(3, 201, 215, 0.08)'
                  : 'rgba(0, 0, 0, 0.03)',
              },
            }),
          };
    return customStyles;
  };

  const handleClick = () => {
    console.info('You clicked the Chip.');
  };

  const [chipData, setChipData] = React.useState([
    { key: 0, label: 'Angular' },
    { key: 1, label: 'jQuery' },
    { key: 2, label: 'Polymer' },
    { key: 3, label: 'React' },
    { key: 4, label: 'Vue.js' },
  ]);

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  };

  const ListItem = styled('li')(({ theme }) => ({
    margin: '5px',
  }));

  return (
    <ModalComponent backdrop="static" keyboard={false} show={openModal} onHide={onCloseModal}>
      <ModalComponent.Header closeButton>
        <ModalComponent.Title>Filtros Múltiples</ModalComponent.Title>
      </ModalComponent.Header>
      <ModalComponent.Body>
        <ContainerFiltros>
          {filas.map(function (itemFila, index) {
            return (
              <ContentFiltros>
                <ContentAction>
                  <SelectOptionActionButton
                    icon={<FaTrashCan />}
                    isAction={true}
                    action={(e) => onDeleteFiltro(itemFila, index)}
                  ></SelectOptionActionButton>
                </ContentAction>

                <ColFlex>
                  <LabelForm
                    width="200px"
                    text_align="start"
                    weight="bold"
                    title={'Condición:'}
                  ></LabelForm>
                  <SelectForm
                    width={'180px'}
                    bValid={true}
                    value={itemFila.selectCondicion}
                    handleChange={(e, v) => handleChangeCondicion(v, itemFila, index)}
                    options={condicionales}
                    defaultValue={[{ label: 'And', value: 1 }]}
                    placeholder={'Seleccione'}
                    // isDisabled={itemFila.selectColumna.length === 0 || itemFila.selectOperador.length}
                  />
                </ColFlex>

                <ColFlex>
                  <LabelForm
                    width="200px"
                    text_align="start"
                    weight="bold"
                    title={'Columna:'}
                  ></LabelForm>
                  <SelectForm
                    width={'180px'}
                    bValid={true}
                    value={itemFila.selectColumna}
                    handleChange={(e, v) => handleChangeColumna(v, itemFila, index)}
                    // options={aplazamientoState.listaMoneda}
                    options={columns}
                    placeholder={'Seleccione'}
                    // value={itemFila.selectColumna}
                  ></SelectForm>
                </ColFlex>

                <ColFlex>
                  <LabelForm
                    width="200px"
                    text_align="start"
                    weight="bold"
                    title={'Operador:'}
                  ></LabelForm>
                  <SelectForm
                    width={'180px'}
                    bValid={true}
                    value={itemFila.selectOperador}
                    handleChange={(e, v) => handleChangeOperador(v, itemFila, index)}
                    options={
                      itemFila.selectColumna.length === 0
                        ? []
                        : listaOperaciones.filter(function (itemOperacion, index) {
                            return itemOperacion.idTipo === itemFila.selectColumna[0].idTipo;
                          })
                    }
                    placeholder={'Seleccione'}
                    // isDisabled={item.selectColumna.length === 0 || item.selectOperador.length}
                  />
                </ColFlex>

                <ColFlex>
                  <LabelForm
                    width="200px"
                    text_align="start"
                    weight="bold"
                    title={'Valor:'}
                  ></LabelForm>

                  {itemFila.selectOperador.length === 0 ? (
                    <Input width={'350px'} placeholder="Ingrese..." disabled={true} />
                  ) : itemFila.selectOperador[0].value === 3 ||
                    itemFila.selectOperador[0].value === 10 ? (
                    // <ContainerSelect>
                    //   <CreatableSelect
                    //     styles={customStyleSelect(true)}
                    //     components={components}
                    //     inputValue={itemFila.newValue}
                    //     isClearable
                    //     // menuIsOpen={false}
                    //     onChange={(evnt) => changeMultiInput(evnt, itemFila, index)}
                    //     onInputChange={(evnt) => handleChangeMultiInput(evnt, itemFila, index)}
                    //     // onChange={(newValue) => setValue(newValue)}
                    //     // onInputChange={(newValue) => setInputValue(newValue)}
                    //     onKeyDown={(evnt) => handleKeyDownMultiInput(evnt, itemFila, index)}
                    //     placeholder="Ingrese..."
                    //     onCreateOption={(evnt) => handleCreateMultiInput(evnt, itemFila, index)}
                    //     value={itemFila.valueFields}
                    //     isMulti
                    //   />
                    // </ContainerSelect>
                    <Paper
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        p: 0.5,
                        m: 0,
                      }}
                      component="ul"
                    >
                      {itemFila.valueFields.map((data) => {
                        let icon;

                        if (data.label === 'React') {
                          icon = <TagFacesIcon />;
                        }

                        return (
                          <ListItem key={data}>
                            <Chip label={data} onDelete={(oEvent) => onDeleteValor(data, index)} />
                          </ListItem>
                        );
                      })}

                      {itemFila.valueFields.length >= 2 &&
                      itemFila.selectColumna[0].idTipo === 3 &&
                      itemFila.selectOperador[0].value === 10 ? (
                        <></>
                      ) : (
                        <SearchFieldAutocompletePagination
                          width={'350px'}
                          data={itemFila.aListaValores}
                          label="languages"
                          pholder="Buscar ..."
                          onSelected={(param) => onMultiSelectValue(param, index)}
                          value={itemFila.newValue}
                          // oValue={itemFila.valueFields.length === 0 ? '' : itemFila.valueFields[0]}
                          text_align={'start'}
                          onChange={(evnt) => handleChangeInput(evnt, itemFila, index)}
                          itemFila={itemFila}
                          fetchDataPaginate={(evnt) => fetchDataPaginate(evnt, itemFila, index)}
                          onKeyDown={(evnt) => handleKeyDownMultiInput(evnt, itemFila, index)}
                        />
                      )}
                    </Paper>
                  ) : (
                    // <Input
                    //   width={'350px'}
                    //   placeholder="Ingrese..."
                    //   value={itemFila.valueFields.length === 0 ? '' : itemFila.valueFields[0]}
                    //   onChange={(evnt) => handleChangeInput(evnt, itemFila, index)}
                    // />

                    <SearchFieldAutocompletePagination
                      width={'350px'}
                      data={itemFila.aListaValores}
                      label="languages"
                      pholder="Buscar ..."
                      onSelected={(param) => onSelectValue(param, index)}
                      value={itemFila.valueFields.length === 0 ? '' : itemFila.valueFields[0]}
                      oValue={itemFila.valueFields.length === 0 ? '' : itemFila.valueFields[0]}
                      text_align={'start'}
                      onChange={(evnt) => handleChangeInput(evnt, itemFila, index)}
                      itemFila={itemFila}
                      fetchDataPaginate={(evnt) => fetchDataPaginate(evnt, itemFila, index)}
                    />
                  )}
                </ColFlex>
              </ContentFiltros>
            );
          })}
        </ContainerFiltros>
      </ModalComponent.Body>
      <ModalComponent.Footer>
        {/* <ButtonForm
      name={"Aplicar"}
      isAction={true}
      action={(e)=>onAplicarFiltros(e,props)}
    />
    <ButtonForm
      name={"Limpiar Filtros"}
      isAction={false}
      action={onLimpiarFiltros}
    />
    <ButtonForm name={"Cancelar"} isAction={false} action={onCloseModal} /> */}
        <ContentFooter>
          <RowFlex style={{ justifyContent: 'start' }}>
            <ButtonForm
              action={(e) => onAddFiltro()}
              name="Agregar Filtro"
              isAction={true}
              typeColor="primary"
            />

            <ButtonForm
              action={(e) => onAplicarFiltrosMultiples()}
              name="Aplicar Filtro"
              isAction={true}
              typeColor="secondary"
            />
          </RowFlex>
          <RowFlex style={{ justifyContent: 'end' }}>
            <ButtonForm
              action={(e) => onLimpiarFiltros()}
              name="Limpiar"
              isAction={false}
              typeColor="tertiary"
            />

            <ButtonForm
              action={(e) => onCancelModal(e)}
              name="Cancelar"
              isAction={false}
              typeColor="warning"
            />
          </RowFlex>
        </ContentFooter>
      </ModalComponent.Footer>
      {loading === true ? <LoadingSpinner /> : <></>}
    </ModalComponent>
  );
};

CustomFilterMixTable.propTypes = {
  columns: PropTypes.element.isRequired,
  openModal: PropTypes.element.isRequired,
  onCloseModal: PropTypes.element.isRequired,
  filas: PropTypes.element.isRequired,
};

const ModalComponent = styled(Modal)`
  .modal-dialog {
    max-width: 2000px !important;
    position: relative;
    display: table; /* This is important */
    overflow-y: auto;
    overflow-x: auto;
    width: auto;
    min-width: 600px;
  }
`;
const ContainerSelect = styled.div`
  width: 350px;
`;
const ContentAction = styled.div`
  display: flex;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 20px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContainerFiltros = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 15px;
  align-items: center;
  width: 100%;
`;

const ContentFiltros = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export default CustomFilterMixTable;
