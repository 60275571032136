import React from 'react';
import styled, { keyframes } from 'styled-components';
import { theme } from '../../common';

export const LoadingPage = () => {
  return (
    <LoaderContainer>
      <Dots>
        <Dot />
        <Dot />
        <Dot />
      </Dots>
      <LoadingText>Cargando...</LoadingText>
    </LoaderContainer>
  );
};

// Animación de expansión de los puntos
const bounce = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
`;

// Estilo del contenedor del loader (pantalla completa)
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo translúcido */
  z-index: 9999;
  flex-direction: column;
  opacity: 0.9; /* Fondo desvanecido */
`;

// Contenedor de los puntos de carga
const Dots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

// Estilo para los puntos de carga
const Dot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${theme.colors.secondary};
  animation: ${bounce} 1.5s ease-in-out infinite;

  &:nth-child(1) {
    animation-delay: 0s;
    background-color: ${theme.colors.primary};
  }

  &:nth-child(2) {
    animation-delay: 0.3s;
    background-color: ${theme.colors.tertiary};
  }

  &:nth-child(3) {
    animation-delay: 0.6s;
    background-color: ${theme.colors.quaternary};
  }
`;

// Estilo para el texto de carga
const LoadingText = styled.div`
  font-size: 24px;
  color: #ffffff;
  margin-top: 20px;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  text-align: center;
  animation: fadeIn 1s ease-in-out infinite;

  @keyframes fadeIn {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
`;
