import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../../common/hooks';
import MainLayout from '../../layout/Layout';
import { LoadingPage } from '../../loadingPage';

// Componente para rutas privadas
const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuth();
  if (loading) {
    return <LoadingPage />; // Aquí puedes poner un componente de carga o spinner
    // return <div>Loading...</div>; // Aquí puedes poner un componente de carga o spinner
  }

  // Si el usuario no está autenticado, redirigir al login
  if (!user.uid) {
    return <Navigate to="/login" />;
  }

  // Si el usuario está autenticado, renderizar los hijos
  return <MainLayout element={children}></MainLayout>;
};

export default PrivateRoute;
